const getSeasonObjectsHandler = (seasonArray) => {
	const newArray = [];
	seasonArray.forEach(wk => {
		const firstDate = wk.saison.value.slice(2, 6);
		const secondDate = wk.saison.value.slice(7, 11);
		newArray.push({
			saison: wk.saison,
			value: wk.saison.objectId,
			label: `Saison ${  firstDate  } / ${  secondDate}`
		});
	});
	return newArray;
};

export default getSeasonObjectsHandler;