import { cloneDeep } from 'lodash';
import { signUpNameHandler } from './signUpHelpers';

const findUntergruppeFromId = (gruppen, id) => {
	let untergruppe;

	gruppen.forEach(group => {
		const ug = group.untergruppen.find(ug => ug.id === id);
		if (ug) {
			untergruppe = ug;
		}
	});
	return untergruppe;
};

const getTeamLabel = (game, games, short, showClass, groups)  => {
	const teamsArray = ['team1', 'team2', 'referee'];
	const teamObject = {
		team1: {},
		team2: {},
		referee: {}
	};
	const meldungen = groups.map(group => group.meldungen.results).flat();

	teamsArray.forEach(teamKey => {
		if (game.type === 'group' || game.type === 'grp' || game.type === 'ent' || game.type === 'akk') {
			groups?.forEach(group => {
				group?.untergruppen?.forEach((ug, index) => {
					if (ug.id === game[`${teamKey}_group`]) {
						const groupCopy = cloneDeep(group);
						const meldungId = groupCopy.untergruppen[index]?.meldungen[game[`${teamKey}_index`] - 1];
						
						teamObject[teamKey] = {
							...teamObject[teamKey], 
							label: signUpNameHandler(meldungId, meldungen, showClass, short).label,
							logo: signUpNameHandler(meldungId, meldungen, showClass, short).logo,
							spielklasse: signUpNameHandler(meldungId, meldungen, showClass, short).spielklasse,
							meldungId
						};
					}
				});
			});
		}
		if (game.type === 'cross' || game.type === 'krz' || game.type === 'place' || game.type === 'plz') {
			const untergruppenIndex = game.group.untergruppen.findIndex(ug => ug.id === game[`${teamKey}_group`]) ;
			const untergruppe = game.group.untergruppen[untergruppenIndex];

			if (game[`${teamKey}_game`] ) {
				const teamGame = games.find(gameToFind => gameToFind.objectId === game[`${teamKey}_game`].objectId);
				const teamIndex =  game[`${teamKey}_index`];
				if (teamIndex === 2 && teamGame) {
					if (teamGame.winner) {
						teamObject[teamKey] = {
							...teamObject[teamKey], 
							label: signUpNameHandler(teamGame?.winner?.objectId, meldungen, showClass, short).label,
							logo: signUpNameHandler(teamGame?.winner?.objectId, meldungen, showClass, short).logo,
							spielklasse: signUpNameHandler(teamGame?.winner?.objectId, meldungen, showClass, short).spielklasse,
							meldungId: teamGame?.winner?.objectId
						};
					} else {
						teamObject[teamKey] = {label: `Sieger aus Spiel ${teamGame.spielNr}`};
					}
				} 
				if (teamIndex === 1 && teamGame) {
					if (teamGame.loser) {
						teamObject[teamKey] = {
							...teamObject[teamKey], 
							label: signUpNameHandler(teamGame?.loser?.objectId, game.group?.meldungen?.results).label,
							logo: signUpNameHandler(teamGame?.loser?.objectId, game.group?.meldungen?.results).logo,
							spielklasse: signUpNameHandler(teamGame?.loser?.objectId, game.group?.meldungen?.results, showClass, short).spielklasse,
							meldungId: teamGame?.loser?.objectId
						};
					} else {
						teamObject[teamKey] = {label: `Verlierer aus Spiel ${teamGame.spielNr}`};
					}
				} 
			} else if ( untergruppe?.abgeschlossen === true) {
				teamObject[teamKey] =  {
					...teamObject[teamKey], 
					label: signUpNameHandler(untergruppe.standings[game[`${teamKey}_index`] - 1]?.meldungId, meldungen, showClass, short).label,
					logo: signUpNameHandler(untergruppe.standings[game[`${teamKey}_index`] - 1]?.meldungId, meldungen, showClass, short).logo,
					spielklasse: signUpNameHandler(untergruppe.standings[game[`${teamKey}_index`] - 1]?.meldungId, meldungen, showClass, short).spielklasse,
					meldungId: untergruppe.standings[game[`${teamKey}_index`] - 1]?.meldungId
				};
			} else if (game[`${teamKey}_index`]) {
				const ug = findUntergruppeFromId(groups, game[`${teamKey}_group`]);
				
				if (ug) {
					teamObject[teamKey] =  {
						...teamObject[teamKey], 
						label: signUpNameHandler(ug.standings[game[`${teamKey}_index`] - 1]?.meldungId, meldungen, showClass, short).label,
						logo: signUpNameHandler(ug.standings[game[`${teamKey}_index`] - 1]?.meldungId, meldungen, showClass, short).logo,
						spielklasse: signUpNameHandler(ug.standings[game[`${teamKey}_index`] - 1]?.meldungId, meldungen, showClass, short).spielklasse,
						meldungId: ug.standings[game[`${teamKey}_index`] - 1]?.meldungId
					};
				}
			} else {
				teamObject[teamKey] = {label: 'Unbestimmt'};
			}
		}
	});

	return teamObject;
};

export default getTeamLabel;