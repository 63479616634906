const getCurrentSeason = (i) => {
	const date = new Date();
	let saison = '';

	const month = date.getMonth() + 1;

	if ((month) < 7) {
		const year1 = date.getFullYear() - 1 + i;
		const year2 = date.getFullYear() + i;
		saison = `s-${  year1  }-${  year2}`;
	}
	if ((month) > 6) {
		const year1 = date.getFullYear() + i;
		const year2 = date.getFullYear() + 1 + i;
		saison = `s-${  year1  }-${  year2}`;
	}
	return saison;
};

export default getCurrentSeason;